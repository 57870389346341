.bg-greenLight {
  background-color: rgba(237, 250, 243, .9);
  background-color: rgba(237, 250, 243, .9);
}


.bg-orangeLight {
  background-color: #FFF9C4;
  background-color: #f8e2d3;
}

.bg-blueLight {
  background-color: #FFFDE7;

}


.divheight {
  display: flex;
  justify-content: center;
  position: relative;
  right: 20%;
  border-radius: 20px;
  width: 90%;
  padding-top: 12px;
  padding-bottom: 12px;
}

.text-green {
  color: #50cd84;
  font-size: 12px;
  font-size: 11px;
  margin-top: -21px;
}

.text-blue {
  color: #4169E1;
  font-size: 12px;
  font-size: 11px;
  margin-top: -7px;
}


.text-yellow {
  color: #FFD600;
  font-size: 12px;
  font-size: 11px;
  margin-top: -7px;
}

.text-red {
  color: red;
  font-size: 12px;
  font-size: 11px;
  margin-top: -7px;

}


.paid {
  color: #2E79E9;
  font-size: 12px;
  font-size: 11px;
  margin-top: -7px;
}

.paid-bg {
  background-color: #D5E4FB;
}

.label {
  color: var(--neutral-900, #242528);

  /* Text s/Medium */
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  /* 19.5px */
  letter-spacing: 0.13px;
}

.input {
  border-radius: 8px;
  border: 1px solid var(--neutral-600, #8e95a1);
}

.edit-btn {
  display: flex;
  width: 100px;
  height: 32px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: var(--brand-blue-600, #296dd2);
  border: none;
  color: white;
}

.view-btn {
  display: flex;
  width: 100px;
  height: 32px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: var(--brand-green-600, #58ca58);
  border: none;
  color: white;
}

.table-header {
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  /* 24px */
  letter-spacing: 0.16px;
  border-top: none;
  border-bottom: solid #173d75;
}


#columHead {
  color: #173d75;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  /* 24px */
  letter-spacing: 0.16px;
}

.table-cell {
  color: #173d75;
  font-size: 16px;
}

#detailSide {
  color: var(--neutral-900, #242528);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 24px */
  letter-spacing: 0.16px;
  font-family: Poppins;
}


.pending {
  display: flex;
  height: 2rem;
  padding: 0.125rem 0.75rem;
  justify-content: center;
  align-items: center;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 1.21875rem */
  letter-spacing: 0.00813rem;
  color: var(--status-colours-amber-600, #d97706);
  border-radius: 6.25rem;
  border: 1px solid var(--status-colours-amber-400, #f59e0b);
  background: var(--status-colours-amber-100, #fef3c7);
  font-family: Poppins;
  text-transform: capitalize;
}

.accepted {
  display: flex;
  height: 2rem;
  padding: 0.125rem 0.75rem;
  justify-content: center;
  align-items: center;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 1.21875rem */
  letter-spacing: 0.00813rem;
  color: var(--status-colours-amber-600, #50cd84);
  border-radius: 6.25rem;
  border: 1px solid var(--status-colours-amber-400, #50cd84);
  background: rgba(237, 250, 243, .9);
  font-family: Poppins;
  text-transform: capitalize;
}

.rejected {
  display: flex;
  height: 2rem;
  padding: 0.125rem 0.75rem;
  justify-content: center;
  align-items: center;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 1.21875rem */
  letter-spacing: 0.00813rem;
  color: red;
  border-radius: 6.25rem;
  border: 1px solid var(--status-colours-amber-400, red);
  background: #ffcdc4;
  font-family: Poppins;
  text-transform: capitalize;
}

.paid {
  display: flex;
  height: 2rem;
  padding: 0.125rem 0.75rem;
  justify-content: center;
  align-items: center;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 1.21875rem */
  letter-spacing: 0.00813rem;
  color: var(--brand-blue-600, #296dd2);
  border: 1px solid;
  background: #e6f5ff;
  border-radius: 15px;
  font-family: Poppins;
  text-transform: capitalize;
}


.detailsBody {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding-bottom: 40px;
}

.insuranceType {
  font-weight: 700;
  font-size: 20px;
  text-transform: uppercase;
}

.sectionHeader {
  font-weight: 500;
  color: gray;
  font-size: 18px;
  margin-bottom: 5px;
}

.gridView {
  display: grid;
  width: "100%";
  row-gap: 10px;
  grid-template-columns: repeat(2,minmax(0, 1fr));
}

.gridView2 {
  display: grid;
  width: "100%";
  row-gap: 10px;
  grid-template-columns: repeat(3,minmax(0, 1fr));
}

.dLable {
  font-weight: 500;
  color: #296dd2
}
