.main-title {
  position: relative;
  margin-top: 4%;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 15px;
  margin-bottom: 15px;
}


.default-link-state {
  color: #FFFFFF;
}

.active-link-state {
  text-decoration: underline;
  color: #86B3F6;
}

.main-title h2 {
  font-size: 50px;
  font-weight: bold;
  margin-bottom: 1px;
  padding-bottom: 5px;
  position: relative;
  color: #001027;
}



.font-loader {
  font-family: 'Poppins';
}


.services-parent {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-around;
  align-items: center;
}

.services-bucket {
  flex-direction: column;
  padding: 3px;
  text-align: center;
  align-self: center;
  justify-content: center;
}

.mobile-iconbox {
  height: 96px;
  width: 120px;
  text-align: center;
}



#services-mobile {
  position: relative;
}

.icon-box-mobile {
  padding: 55px 45px;
  transition: all ease-in-out 0.4s;
  background: #fff;
  border-radius: 40px;
}


.services-mobile .icon-box1 {
  box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
  padding: 50px 30px;
  cursor: pointer;
  background: #fff;
  border-radius: 20px;
}

.services-mobile .icon-box .icon {
  margin-bottom: 10px;
}

.services-mobile .icon-box .icon i {
  color: #47b2e4;
  font-size: 36px;
  transition: 0.3s;
}

.services-mobile .icon-box h4 {
  font-weight: 500;
  margin-bottom: 15px;
  font-size: 24px;
}

.services-mobile .icon-box h4 a {
  color: #37517e;
  transition: ease-in-out 0.3s;
}

.services-mobile .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.services-mobile .icon-box:hover {
  transform: translateY(-10px);
}

.services-mobile .icon-box:hover h4 a {
  color: #47b2e4;
}


.services-img {
  position: relative;
  height: 50px;
  width: 50px;
  margin-top: -50px;
  left: 30px;
}

.services-name {
  font-size: 1rem;
  color: black;
  margin-top: -10px;
}




.service-box {
  height: 96px;
  width: inherit;
  display: flex;
  text-align: center;
  margin-bottom: 15px;
  border: #0E3E8633 solid 1px;
  flex-direction: column;
}

.coming-soon-parent {
  display: flex;
  justify-content: center;
}

.coming-soon {
  font-size: 0.7rem;
  padding: 0.2rem;
  align-self: stretch;
  border: #0E3E8633 solid 1px;
  height: 1.5rem;
  border-radius: 15px;
  width: 120px;
  justify-content: center;
}

.step-container {
  display: flex;
  flex-direction: row;
  text-align: center;
  position: relative;
  justify-content: space-around;
}

.step {
  padding: 15px;
  margin: 15px;
  max-width: 268px;
  text-align: center;
}

.step-img {
  position: relative;
  margin-bottom: 20px;
}

.step-caption {
  width: inherit;
  text-align: center;
  font-size: 24px;
  color: #2E79E9;
  white-space: normal;
}

.step-desc {
  font-size: 15px;
  color: #0E3E8699;
  white-space: normal;
  text-align: center;
}

.side-menu-button {
  padding: 5px;
  margin: 10px;
  font-size: 36px;
  text-align: center;
  background: transparent;
  border: none;
}

.screen-section-container {
  background-color: #D8F9EB;
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.click-away {
  font-size: 64px;
  color: #092653;
}

.star{
  color: red;
}

.get-quote-btn {
  box-shadow: 0px 4px 18px #2E79E9;
  background-color: #2E79E9;
  border: #2E79E966;
  border-radius: 40px 40px 40px 40px;
  color: #F6F8FB;
  font-size: 18px;
}

.text-container {
  width: 100%;
}

.FAQ-answer {
  white-space: normal;
}

.screen-img {
  width: 701px;
  height: 457px;
}

.footer-img {
  width: 536px;
  height: 57.62px;
}

.customer-change-link-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.customer-change-link {
  display: flex;
  color: #2E79E9;
  font-size: 20px;
}

.form-heading-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sign-up-title {
  color: #0B5BD2;
font-size: 80px;
}


@media only screen and (max-width: 480px) {


  .sign-up-title{
    font-size: 40px;
  }

  .customer-change-link {
    font-size: 18px;
  }

  .form-heading-container {
    flex-direction: column;
  }

  

  .footer-img {
    width: 400px;
    object-fit: contain
  }

  .screen-img {
    width: 400px;
    object-fit: contain
  }
}


@media only screen and (max-width: 720px) {
  .services-img {
    position: relative;
    left: 16px;
  }
}

@media only screen and (max-width: 360px) {
  .footer-img {
    width: 300px;
    object-fit: contain
  }

  .screen-img {
    width: 300px;
    object-fit: contain
  }
}

@media only screen and (max-width: 375px) {
  .footer-img {
    width: 300px;
    object-fit: contain
  }

  .screen-img {
    width: 300px;
    object-fit: contain
  }
}