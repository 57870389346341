.table-header {
  border-bottom: #173d75 solid 1px;
}
body{
  font-family: Poppins;
}

#columHead {
  color: #173d75;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
  letter-spacing: 0.16px;
}

#titleSide {
  color: #173d75;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  letter-spacing: 0.16px;
  font-family: Poppins;
}

#detailSide {
  color: var(--neutral-900, #242528);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: 0.16px;
  font-family: Poppins;
}

.active-tab {
  display: flex;
  flex-direction: row;
  padding: 10px;
  background-color: white;
  color: #2055a3;
  border: 2px solid #2055a3; /* Green */
  border-radius: 0.5rem;
  border: 1px solid var(--brand-blue-700, #2055a3);

  /* Shadow/s */
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
}

.rejected {
  display: flex;
  height: 2rem;
  padding: 0.125rem 0.75rem;
  justify-content: center;
  align-items: center;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.21875rem */
  letter-spacing: 0.00813rem;
  color: red;
  border-radius: 6.25rem;
  border: 1px solid var(--status-colours-amber-400, red);
  background: #ffcdc4;
  font-family: Poppins;
  text-transform: capitalize;
}

.tab-button {
  display: flex;
  flex-direction: row;
  padding: 10px;
  background: transparent;
  border-radius: 0.5rem;
  color: #474a50;
  font-family: Poppins;

  border: none;
}

.tab-button :hover {
  background: #4877bd;
}

.badgeStyle {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2055a3;
  color: var(--brand-blue-50, #e6f5ff);
  font-family: Poppins;
  font-size: 0.76469rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.147rem */
  letter-spacing: 0.00763rem;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin-left: 5px;
  font-family: Poppins;
}

.answered-request {
  display: flex;
  height: 2rem;
  padding: 0.125rem 0.75rem;
  justify-content: center;
  align-items: center;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.21875rem */
  letter-spacing: 0.00813rem;
  color: var(--brand-blue-600, #296dd2);
  border: 1px solid;
  background: #e6f5ff;
  border-radius: 15px;
  font-family: Poppins;
}

.unanswered-request {
  display: flex;
  height: 2rem;
  padding: 0.125rem 0.75rem;
  justify-content: center;
  align-items: center;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.21875rem */
  letter-spacing: 0.00813rem;
  color: var(--status-colours-amber-600, #d97706);
  border-radius: 6.25rem;
  border: 1px solid var(--status-colours-amber-400, #f59e0b);
  background: var(--status-colours-amber-100, #fef3c7);
  font-family: Poppins;
}

.title-side {
  width: 40%;
}
.detail-side {
  width: 60%;
}

.cancel-btn {
  display: flex;
  font-family: Poppins;

  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background: transparent;
  border-radius: 0.5rem;
  color: #e11d48;
  border: 1px solid;
}

.download-btn {
  display: flex;
  font-family: Poppins;

  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background: transparent;
  border-radius: 0.5rem;
  color: #00C853;
  border: 1px solid;
}

.accept-btn {
  font-family: Poppins;

  display: flex;
  padding: 0.5rem 0.625rem;
  align-items: center;
  gap: 0.25rem;
  border-radius: 0.5rem;
  background: var(--brand-blue-600, #296dd2);
  color: var(--brand-blue-100, #eaf2fd);
  justify-content: space-between;
  /* Text m/Normal */
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.5rem */
  letter-spacing: 0.01rem;
  margin-left: 15px;
  border: none;
}

.view-details-btn {
  background: transparent;
  border: none;
  color: #296dd2;
  text-decoration: underline;
  font-family: Poppins;
}

.modal-title {
  color: var(--brand-blue-800, #173d75);

  /* Text l/Medium */
  font-family: Poppins;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 1.5rem */
  letter-spacing: 0.0125rem;
}

.quote-title-items {
  color: var(--neutral-800, #474a50);

  /* Text m/Normal */
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.5rem */
  letter-spacing: 0.01rem;
}

.quote-detail-items {
  color: var(--brand-blue-800, #173d75);
  /* Text m/Medium */
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 1.5rem */
  letter-spacing: 0.01rem;
}

.cancel-btn-modal {
  display: flex;
  font-family: Poppins;

  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background: transparent;
  border-radius: 0.5rem;
  color: #e11d48;
  border: 1px solid;
}

.accept-btn-modal {
  font-family: Poppins;

  display: flex;
  padding: 0.5rem 0.625rem;
  align-items: center;
  gap: 0.25rem;
  border-radius: 0.5rem;
  background: var(--brand-blue-600, #296dd2);
  color: var(--brand-blue-100, #eaf2fd);
  justify-content: center;
  /* Text m/Normal */
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.5rem */
  letter-spacing: 0.01rem;
  margin-left: 15px;
  border: none;
}

.content-item-container {
  display: flex;
  width: 265px;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 1px;
  border-radius: 8px;
  border: 1px solid var(--neutral-900, #242528);
  background: var(--neutral-50, #f8f8fa);
  margin-bottom: 10px;
}

.content-item-title {
  color: var(--neutral-900, #242528);
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 19.5px */
  letter-spacing: 0.13px;
}

.content-item {
  color: var(--neutral-900, #242528);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 15px */
  letter-spacing: 0.1px;
}



