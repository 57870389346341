.px-3 {
  padding-left: 0.1rem;
  padding-right: 0.1rem;
}

.py-1 {
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}

.bg-greenLight {
  background-color: rgba(237, 250, 243, 0.9);
  background-color: rgba(237, 250, 243, 0.9);
}

.bg-orangeLight {
  background-color: #f8e2d3;
  background-color: #f8e2d3;
}

.bg-blueLight {
  background-color: #f0ffff;
  background-color: #f0ffff;
}

.rounded-12 {
  border-radius: 12px;
}

.justify-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}

.flex {
  display: flex;
}

.divheight {
  height: 20px;
  width: 60px;
  margin-left: 30px;
}

/* .text-green {
  padding-top: 15px;
  color: #50cd84;
  font-size: 12px;
  margin-top: -7px;
} */

.text-blue {
  padding-top: 15px;
  color: #4169e1;
  font-size: 12px;
}

.text-orange {
  color: #ff9966;
  padding-top: 15px;
  font-size: 12px;
}

.intro-text {
  position: relative;
  display: flex;
  flex-direction: column;
  /* padding-left: 55px; */
  margin-top: 5px;
  margin-bottom: 10px;
}

.top-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  padding: 5px;
  justify-content: space-between;
}

.username {
  font-size: 25px;
  font-weight: 700;
  color: black;
}

.firstRow {
  display: flex;
  flex-direction: row;
  width: 94%;
  padding-bottom: 25px;
  padding-left: 20px;
  padding-right: 20px;
}

.activities {
  border-radius: 3px;
  margin-right: 20px;
  /* border: 'solid' 4px black; */
  height: 200px;
  background-color: white;
  display: block;
  padding: 10px;
  padding-bottom: 30px;
  min-width: 500px;
  flex-basis: 100%;
  box-shadow: '10px 30px 10px #9E9E9E';
}

.activities-header {
  text-align: left;
  position: relative;
  padding-bottom: 15px;
  padding-left: 15px;
  font-size: 1.7rem;
  color: #0e3e86;
  font-weight: 700;
}

.pie-chart-container {
  position: relative;
  margin-top: 10%;
  left: 10%;
  height: 60%;
  width: 85%;

}

.policies-container {
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  margin-top: 45px;
  min-width: 550px;
  background: white;
}

.policies-headings {
  /* border: solid; */
  padding-left: 10px;
  padding-left: 10px;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.policies-heading-1 {
  font-size: 1.7rem;
  color: #0e3e86;
  font-weight: 700;
}

.policies-heading-2 {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 1em;
  color: #0e3e86;
  font-weight: 600;
}

.policy-emptyState-box{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 1rem;
}



.policy-img {
  margin-top: 50px;
  width: 150px;
  height: 130px;
  margin-bottom: 15px;
}

.policy-img-heading {
  font-size: 45px;
  color: #0e3e86;
}

.transaction-container {
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  padding-left: 25px;
  padding-bottom: 30px;
  background: white;
}

.transaction-heading {
  position: relative;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 1.7rem;
  color: #0e3e86;
  font-weight: 700;
}

.transaction-img {
  margin-top: -50px;
  width: 150px;
  height: 130px;
  margin-bottom: 15px;
}

@media only screen and (max-width: 720px) {
  .top-container {
    flex-direction: column;
    align-items: center;
  }

  .username {
    font-size: 20px;
  }

  .policies-headings {
    width: 100%;
  }

  .firstRow {
    flex-direction: column;
  }

  .activities {
    height: 350px;
    min-width: 500px;
  }

  .policies-container{
    height: 350px;
    min-width: 500px;
    margin-bottom: 35%;
  }

  .transaction-heading {
    font-size: 18px;
    font-weight: 700;
  }

  .activities-header {
    font-size: 18px;
    font-weight: 700;
  }

  .policies-heading-1 {
    font-size: 18px;
    font-weight: 700;
  }

  .policies-heading-2 {
    font-size: 16px;
  }

  .policy-img-heading {
    font-size: 25px;
  }
}

@media only screen and (max-width: 420px) {
  .top-container {
    flex-direction: column;
    align-items: center;
  }

  .username {
    font-size: 20px;
  }

  .activities {
    height: 250px;
    min-width: 350px;
  }

  .policies-container{
    height: 250px;
    min-width: 350px;
    margin-bottom: 45%;
  }

  .policies-headings {
    width: 100%;
    margin-top: 10%;
  }
  /* .intro-text {
    padding-left: 70px;
  } */

  .firstRow {
    flex-direction: column;
  }


  .policy-img {
    margin-top: 50px;
    width: 50px;
    height: 50px;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 576px) {
  .top-container {
    flex-direction: column;
    align-items: center;
    position: relative;
    top: -30px;
  }

  .username {
    font-size: 20px;
  }

  .activities {
    height: 250px;
    min-width: 350px;
  }

  .policies-headings {
    width: 100%;
    margin-top: 10%;
  }

  .firstRow {
    flex-direction: column;
  }

  .activities {
    margin-bottom: 15px;
    width: 100%;
  }

  .intro-text {
    padding-left: 1px;
  }
  .policies-headings{
   position: relative;

  top: -50px;
  
   padding-left: 20px;
   padding-right: 10px;
  }

  .transaction-container{
    position: relative;
    top:-150px;
    width: 100%;
  }

  .trans-table{
    position: relative;
    top:10px;
  }

  /* .policy-img-heading {
    font-size: 25px;
  } */

  .policy-img-container {
    margin-top: -15%;
  }
}

.bg-greenLight {
  background-color: rgba(237, 250, 243, 0.9);
  background-color: rgba(237, 250, 243, 0.9);
}

.bg-orangeLight {
  background-color: #fff9c4;
  background-color: #f8e2d3;
}

.bg-blueLight {
  background-color: #fffde7;
}

.divheight {
  display: flex;
  justify-content: center;
  position: relative;
  left: -26%;
  border-radius: 20px;
  width: 90%;
  padding-top: 12px;
  padding-bottom: 12px;
}

.text-green {
  color: #ffd600;
  font-size: 12px;
  font-size: 11px;
  margin-top: -7px;
}

.text-blue {
  color: #4169e1;
  font-size: 12px;
  font-size: 11px;
  margin-top: -7px;
}

.text-yellow {
  color: #ffd600;
  font-size: 12px;
  font-size: 11px;
  margin-top: -7px;
}

.text-red {
  color: red;
  font-size: 12px;
  font-size: 11px;
  margin-top: -7px;
}

.paid {
  color: #2e79e9;
  font-size: 12px;
  font-size: 11px;
  margin-top: -7px;
}

.paid-bg {
  background-color: #d5e4fb;
}

.font-loader {
  font-family: 'Poppins';
}
