/* Write CSS Here */

.fade-enter {
  opacity: 0;
}

.fade-exit {
  opacity: 1;
}

.fade-enter-active {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
}

.fade-enter-active,
.fade-exit-active {
  transition: opacity 300ms;
}

.active-class {
  color: rgb(46, 121, 233);
  height: 35px;
  width: 35px;
  position: relative;
  top: -5px;
}

.progress-value {
  position: relative;
  color: rgb(46, 121, 233);
}

.progress {
  width: 300px;
  margin-right: 15px;
  margin-left: 15px;
}

.progress-mobile {
  width: 300px;
  margin-right: 15px;
  margin-left: 15px;
}

.progress-icons-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: inherit;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
}


.font-loader {
  font-family: 'Poppins';
}

.form-container {
  justify-content: center;
  align-content: center;
  align-self: center;
  padding: 15px;
  position: relative;
}

.form-header {
  position: relative;
  text-align: center;
  margin-top: 20px;
  width: 100%;
}

@media only screen and (min-width: 1366px) {
  .form-container {
    min-width: 700px;
  }
}

@media only screen and (max-width: 375px) {
  .progress {
    width: 100px;
  }
}


@media only screen and (max-width: 353px) {
  .progress-icons-container {
    padding: 10px;
    position: relative;
    left: 25px;
  }

  .form-container {
    position: relative;
    left: 28px;
  }

  .progress {
    width: 110px;
  }
}

@media only screen and (max-width: 415px) {
  .progress-icons-container {
    padding: 10px;
    position: relative;
    left: 25px;
  }

  .form-container {
    position: relative;
    left: 10x;
  }

  .progress {
    width: 110px;
  }
}
