.bg-greenLight {
    background-color: rgba(237,250,243,.9);
    background-color: rgba(237,250,243,.9);
}


.bg-orangeLight {
    background-color: #FFF9C4;
    background-color: #f8e2d3;
}

.bg-blueLight {
    background-color: #FFFDE7;

}


.divheight{
    display: flex;
    justify-content: center;
    position: relative;
    right: 20%;
    border-radius: 20px;
    width: 90%;
    padding-top: 12px;
    padding-bottom: 12px;
}

.text-green{
    color: #50cd84;
    font-size: 12px;
    font-size: 11px;
    margin-top: -7px;
}

.text-blue{
    color: #4169E1;
    font-size: 12px;
    font-size: 11px;
    margin-top: -7px;
}


.text-yellow{
    color: #FFD600;
    font-size: 12px;
    font-size: 11px;
    margin-top: -7px;
}

.text-red{
    color: red;
    font-size: 12px;
    font-size: 11px;
    margin-top: -7px;

}


.paid {
    color: #2E79E9;
    font-size: 12px;
    font-size: 11px;
    margin-top: -7px;
}

.paid-bg {
    background-color: #D5E4FB;
}






