.TopCardsText {
    position: relative;
    font-weight: 700;
    color: #0E3E86;
    right: -10px;
    font-size: 0.9rem;
}

.topCard-vals{
    display: flex;
    align-items: center;
    margin-top: -15px;
}

.TopCards {
    display: flex;
    flex-direction: column;
    margin: 5px;
    min-width: 210px;
    max-height: 120px;
}

.TopCards .card {
    height: 180px;
    background-color: white;
    border-radius: 8px;
}

.policyChartContainer {
    background-color: white;
    min-height: 550px;
    border-radius: 8px;
}


.policyChart {
    border-radius: 8px;
    min-height: 480px;
}

.overviewContainer {
    display: flex;
    flex-direction: column;
    min-height: 500px;
    width: 300px;
}

.overViewCard {
    height: 230px;
    background-color: white;
    border-radius: 8px;
    margin: 10px;
}

.valuesContainer {
    display: flex;
    flex-direction: column;
    padding-top: 50px;
}

.valuesCard {
    height: 100px;
    background-color: white;
    border-radius: 8px;
    width: 250px;
}

.quoteBarPlot {
    height: 260px;
    background-color: white;
    border-radius: 8px;
    margin-bottom: 0px;
    width: 250px;
}




@media(max-width: 1366px) {

    .TopCardsText {
        bottom: -40px;
        font-size: 10px;
    }

    .TopCards {
        min-width: 200px;
        max-height: 180px;
    }

    .TopCards .card {
        height: 100px;
    }

    policyChartContainer{
        width: 66px;
    }

    .policyChart {
        width: 687px;
    }



    .overviewContainer {
        margin-top: 25px;
        width: 200px;
        margin-right: 10px;
    }
    
    .valuesContainer{
        position: relative;
        left: -5px;
    }

    .valuesCard{
        width: 180px;
    }


    
   

    .overViewCard {
        padding: 5px;
        height: 180px;
        width: 180px;
    }

    .quoteBarPlot {
        height: 185px;
        width: 180px;
    }

}


/* 

@media(max-width: 1700px) {

    .TopCardsText {
        bottom: -40px;
        font-size: 10px;
    }

    .TopCards {
        min-width: 250px;
        max-height: 230px;
    }

    .TopCards .card {
        height: 120px;
    }

    policyChartContainer{
        width: 700px;
    }

    .policyChart {
        width: 700px;
    }



    .overviewContainer {
        margin-top: 18px;
        width: 230px;
        margin-right: 6px;
    }
    
    .valuesContainer{
        position: relative;
        left: 0px;
    }

    .valuesCard{
        width: 200px;
    }


    
   

    .overViewCard {
        padding: 5px;
        height: 200px;
        width: 200px;
    }

    .quoteBarPlot {
        height: 200px;
        width: 230px;
    }

}




@media(max-width: 1800px) {

    .TopCardsText {
        bottom: -30px;
        font-size: 15px;
    }

    .TopCards {
        min-width: 230px;
        max-height: 200px;
    }

    .TopCards .card {
        height: 150px;
    }

    policyChartContainer{
        width: 700px;
    }

    .policyChart {
        width: 800px;
    }



    .overviewContainer {
        margin-top: 25px;
        width: 230px;
        margin-right: 70px;
    }
    
    .valuesContainer{
        position: relative;
        left: 0px;
    }

    .valuesCard{
        width: 280px;
    }


    
   

    .overViewCard {
        padding: 5px;
        height: 200px;
        width: 280px;
    }

    .quoteBarPlot {
        height: 200px;
        width: 280px;
    }

} */



