.font-loader {
    font-family: 'Poppins';
}


.main-container {
    display: flex;
    justify-content: center;
    height: 100vh;
    align-items: center;
    flex-direction: column;
}

.title {
    font-size: 70px;
    color: black;
}

.sub-title-container{
    margin-top: 20px;
    margin-bottom: 50px;
}

.sub-title {
    color: #4288f180;
}



.logo-container {
    position: relative;
    margin-top: -250px;
    margin-bottom: 5px;
}

.logo {
    height: 200px;
    width: 200px;
}

.button-container {
    display: flex;
    flex-direction: row;
}


 .getstarted,
 .getstarted:focus {
  padding: 8px 20px;
  margin-left: 30px;
  border-radius: 50px;
  color: #fff;
  font-size: 14px;
  border: 2px solid #47b2e4;
  font-weight: 600;
}

 .getstarted:hover,
.getstarted:focus:hover {
  color: #fff;
  background: #31a9e1;
}