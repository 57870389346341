body {
  font-family: Poppins;
}

hr.colored {
  border: 0;
  /* in order to override TWBS stylesheet */
  height: 4px;
  background: -webkit-linear-gradient(
    left,
    rgb(144, 104, 25) 2%,
    rgb(218, 160, 109) 100%
  );
  /* Chrome10+,Safari5.1+ */
  opacity: 1;
}

hr.colored1 {
  border: 0;
  /* in order to override TWBS stylesheet */
  height: 4px;
  background: -webkit-linear-gradient(left, rgb(14, 62, 134) 2%, #add8e6 100%);
  /* Chrome10+,Safari5.1+ */
  opacity: 1;
}

.requests-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.requests-sub-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.request-child-container {
  margin: 10px;
  padding: 15px;
  border-radius: 1px;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.5);
  min-height: 700px;
  background-color: white;
  width: 100%;
}

.request-holder {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 10px;
}

.font-loader {
  font-family: 'Poppins';
}

@media only screen and (max-width: 720px) {
  .requests-container {
    margin: 0px;
  }

  .requests-container {
    position: relative;
    margin-top: 50px;
  }
}

.home-tabs {
  padding: 12px;
  color: #474a50;
  cursor: pointer;
}

.home-tabs-active {
  padding: 12px;
  color: #296dd2;
  border-bottom: 2px solid;
  cursor: pointer;
}

.dropdown-btn {
  display: flex;

  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--neutral-700, #6b7079);
  color: #6b7079;
  background: transparent;
}

.dropdown-item-container {
  display: flex;
  padding: 16px 12px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 12px;
  border: 1px solid var(--neutral-700, #6b7079);
  background: var(--basics-white, #fff);

  /* Shadow/m */
  box-shadow: 0px 4px 15px 0px rgba(7, 15, 35, 0.1);
}

.dropdown-item {
  display: flex;
  justify-content: flex-start;
  padding-left: 10px;
  padding-right: 10px;
}


.table-header {
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
  letter-spacing: 0.16px;
  border-top: none;
  border-bottom: solid #173d75;
}

.table-cell {
  color: #173d75;
  font-size: 16px;
}


.column-title {
  color: var(--brand-blue-800, #173D75);

/* Text m/Semi Bold */
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 150%; /* 24px */
letter-spacing: 0.16px;
}
