
html, p, body{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}


.cardHeader{
    color: #2E79E9;
    font-size: 24px;
}

.cardSubTitle{
    color: #6F7A89CC;
    font-size: 14px;
}

.font-loader {
    font-family: 'Poppins';
  }

@media(max-width: 1366px) {
    .cardHeader{
        font-size: 15px;
    }

    .cardSubTitle{
        font-size: 11px;
    }
}