svg.MuiSvgIcon-root.MuiSvgIcon-colorAction {
  color: #0e3e86 !important;
}

.font-loader {
  font-family: 'Poppins';

  /* font-weight: 700; */
}

.request-back-btn {
    position: relative;
    right: 24px;
    display: flex;
    padding: 0.5rem 1rem;
    align-items: center;
    gap: 0.5rem;
    align-self: stretch;
  color: var(--brand-blue-50, #e6f5ff);
  background: var(--brand-blue-700, #2055A3);
  /* Text m/Normal */
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 1.5rem */
  letter-spacing: 0.01rem;
  border: none;
}
