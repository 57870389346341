ul#horizontal-list {
	list-style: none;
	padding-top: 20px;
	}
	ul#horizontal-list li {
		display: inline;
        padding-left: 20px;
        cursor: pointer;
	}

.inputStyle{
	height: 60;
	border-radius: 5px;
}

label{
	font-size: 16px;
	color: #0E3E86;
	font-weight: 500;
}

.star{
	color:#EA4C4C;
}


.form-class {
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr)); 
	gap: 1rem; 
}

.form-item{
	grid-column: span 1 / span 1; 
	
}
